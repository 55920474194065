<template>
<div>
	<div>
		<div v-show="!isLoading && this.dataCards">
			<div class="container-page">
				<div class="header-selection">
					<div class="title-selection">{{ textTitle }}</div>
					<div class="searchBar">
						<input
							type="text"
							v-model="searchInput"
							class="searchBar--input"
							@input="filteredOrganization()"
							:placeholder="'Pesquisar uma Organização'"
						>
						<i class="searchBar--input__icon"></i>
					</div>
				</div>

				<div class="container" v-if="isChange">
					<div class="card-title">{{ 'Organização Atual' }}</div>
					<div class="card-line"></div>
					<div class="card-container">
						<div class="card-current">
							<div class="iconCurrent"/>

							<div class="card-body">
								<div v-if="this.dataCardCurrent?.owner?.picture" class="card-picture">
									<img :src="this.dataCardCurrent?.owner?.picture" alt="picture" class="picture" />
								</div>
								<div v-else class="card-picture">
									<div class="initials">
										{{ getInitials(this.dataCardCurrent?.description)}}
									</div>
								</div>
							</div>

							<div class="card-divider"/>
							<div class="card-footer">
								<p class="card-info">{{ this.dataCardCurrent?.description }}</p>
								<p v-if="this.dataCardCurrent?.owner?.identificationDocument?.lenght > 11" class="card-info card-text">{{ maskCnpj(this.dataCardCurrent?.owner?.identificationDocument) }}</p>
								<p v-else class="card-info card-text">{{ maskCpf(this.dataCardCurrent?.owner?.identificationDocument) }}</p>
							</div>
						</div>
					</div>
				</div>

				<div class="container">
					<div class="card-title">{{ 'Organizações Disponíveis' }}</div>
					<div class="card-line"></div>
					<div class="card-container">
						<div
							v-for="item in dataCards"
							:key="item.id"
							class="card-select-organization"
							@click="acessOrganization(item)"
						>
							<span class="tooltipAliare">
								<p class="info-title">{{ 'Acessar Organização'	}}</p>
								<p class="info-organization">{{ item.description }}</p>
							</span>

							<div class="card-body">
								<div v-if="item?.owner?.picture" class="card-picture">
									<img :src="item.owner.picture" alt="picture" class="picture" />
								</div>
								<div v-else class="card-picture">
									<div class="initials">
										{{ getInitials(item?.description)}}
									</div>
								</div>
							</div>

							<div class="card-divider"/>
							<div class="card-footer">
								<p class="card-info">{{ item.description }}</p>
								<p v-if="item?.owner?.identificationDocument?.lenght > 11" class="card-info card-text">{{ maskCnpj(item?.owner?.identificationDocument) }}</p>
								<p v-else class="card-info card-text">{{ maskCpf(item?.owner?.identificationDocument) }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Loading
			:organizationName="true"
			:title="this.organization"
			v-show="isLoading"
		/>
	</div>
	<ScrollInfinite v-if="scrollInfinite" @endOfScroll="pagination()" class="scroll-positioning" />
</div>
</template>

<script>
import mixins from 'vue-typed-mixins';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import { accessIntegrationPanelPermission } from '@/components/mixins/permissions/panelInfo';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import { searchPermissions, createNewAccount } from '@/components/helpers';
import TokenService from '@/services/token.service';
import Loading from '@/components/Loading.vue';

import { mapActions } from 'vuex';
import WebStorage from '@/common/WebStorage';
import { I18nClass } from '../plugins/i18n/index';

export default mixins(
	accessIntegrationPanelPermission,
).extend({
	name: 'SelectOrganization',
	components: {
		Loading,
		ScrollInfinite,
	},
	data() {
		return {
			userName: '',
			searchInput: "",
			organization: '',

			dataCards: [],
			dataCardsTemp:[],
			dataCardCurrent: [],

			page: 1,
			userId: '',
			tenantId:'',
			pageSize: 15,
			isLoading: false,
			scrollInfinite: true,

			tokenData: WebStorage.getItem('tokenData'),
			accountInfo: JSON.parse(WebStorage.getItem('accountInfo')),
		};
	},
	created() {
		this.getUser();
	},
	async mounted() {
		this.getInfos();
	},
	computed: {
		isChange() {
			return this.$route.name === 'mudancaDeOrganizacao';
		},

		textTitle() {
			return this.isChange ? 'Para qual organização você deseja mudar?' : `Olá, ${this.userName }! Com qual Organização você trabalhará?`
		}
	},
	methods: {
		...mapActions('aliareAccount', ['getPermissionFromUser', 'getUserId', 'getAccountDataV2','getAccountTenantV2', 'getUserPermissionById']),

    maskCpf(cpf) {
      return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    maskCnpj(cnpj) {
      return cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
		getUser() {
			this.getUserId(this.tokenData.sub).then((user) => {
				this.userName = user.person;
			});
		},

		async getInfos() {
			const cards = JSON.parse(WebStorage.getItem('cardOrganization'));

			if(this.isChange){
				await this.getInfoOrganizationCurrent();
				await this.otherOrganizations(this.accountInfo?.tenantId, cards);
				return;
			}

			await this.validationAccessIntegrationPanel();

			this.dataCards = cards;
			this.dataCardsTemp = this.dataCards;
			this.scrollInfinite = this.dataCards.length >= 14;
		},
		async getInfoOrganizationCurrent() {
			this.dataCardCurrent = await this.getAccountDataV2(this.accountInfo.tenantId);
		},
		otherOrganizations(id, data) {
			const filteredData = data.filter(item => item.id !== id);
			this.dataCards = [...this.dataCards, ...filteredData];
			this.dataCardsTemp = this.dataCards;

			this.scrollInfinite = this.dataCards.length >= 14;
		},

		async acessOrganization(item) {
			this.scrollInfinite = false;
			this.organization = item.description;

			let userId = this.tokenData.sub;
			let tenantId = this.tokenData.Tenant;

			if (this.tokenData.Level === 'N2' || (this.tokenData.Level !== 'N2' && !this.tokenData.hasPermission)) {
				userId = item.account.userId;
				tenantId = item.id;
			}
			this.isLoading = true;

			if(this.isChange){
				WebStorage.removeItem('isFirstTime');
				this.$store.commit('setWorkspace', {});
				WebStorage.setItem('workspace', JSON.stringify({}));
			}

			await this.verifyPermissionAccess(userId, tenantId, item);
		},
		async verifyPermissionAccess(userId, tenantId, item) {
			try {
				const hasPermission = await searchPermissions(userId, tenantId);

				if (hasPermission) {
					let accountInfo = await createNewAccount(item);
					accountInfo = {
						...accountInfo,
						principalAdminUser: item.principalAdminUser,
						adminUsers: item.adminUsers,
					}

					setupHeaderAliareTenant(item.id);
					WebStorage.setItem('tenantId', item.id);
					WebStorage.setItem('accountName', item.description);
					WebStorage.setItem('accountInfo', JSON.stringify(accountInfo));

					if (this.isChange) {
						this.$root.$emit("reloadHeader");
					}

					// if (this.canViewIntegrationPanel()) {
					// 	this.$router.push('/painel-de-integracoes');
					// } else {
					// 	this.$router.push('/workspace');
					// }
					this.$router.push({
						name: 'workspace',
						params: {
							organizationId: item.id,
						}
					});
				} else {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.noPermissionOrganization'),
						status: 'error',
					});
				}
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications,
					status: 'error',
				});
			} finally {
				this.isLoading = false;
			}
		},

		async pagination() {
			const response = await searchPermissions(this.tokenData.sub, this.tokenData.Tenant);

			if (this.tokenData.Level === 'N1' || this.tokenData.Level === 'N0' && response) {
				this.page++;
				this.scrollInfinite = true;
				const payload = { pageSize: this.pageSize, page: this.page };

				try {
					const response = await this.getAccountTenantV2(payload);

					const filteredData = response.data.filter(account => account.active && !account.blocked);
					this.dataCards.push(...filteredData);
					this.dataCardsTemp = this.dataCards;

					if (response.data.length === 0) {
						this.scrollInfinite = false;
					}
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			}
		},
		filteredOrganization:
		_.debounce(async function (e) {
			this.scrollInfinite = false;
			const canFilterByPermission = this.tokenData.Level === 'N2' || (this.tokenData.Level !== 'N2' && !this.tokenData.hasPermission);

			if (!this.searchInput.length) {
				this.dataCards = this.dataCardsTemp;
				this.scrollInfinite = canFilterByPermission ? false : true;

			} else {
				if (canFilterByPermission) {
					this.dataCards = this.dataCardsTemp?.filter(item => item?.description?.toLowerCase().includes(this.searchInput.toLowerCase()));

				} else {
					this.dataCards = [];
					const response = await this.getAccountTenantV2({ search: this.searchInput });
					this.dataCards = response.data.filter(item => item.active && !item.blocked);
				}
			}
		}, 500),
		getInitials(text) {
			if (!text) return;

			const nome = text?.split(' ');
			return (nome[0]?.charAt(0) + (nome[1] ? nome[1]?.charAt(0) : ''))?.toUpperCase();
		},
  }
});
</script>

<style lang="scss" scoped>
.container-page {
	margin: 0px 10px 0 25px;
	align-items: center;
  display: flex;
  flex-direction: column;
}

.card-current {
  width: 250px;
  height: 217px;
  background-color: #1F2B40;
  display: flex;
  flex-direction: column;
  border: 2px solid #34476A;
	border-radius: 4px;
  margin: 14px 6px;
	border: 2px solid #00C3DD;
}

.iconCurrent {
	border-radius: 100%;
	background-image: url('../assets/checkIconAlertBlue.svg');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 127px;
  top: 51px;
	width: 32px;
	height: 32px;
}

.header-selection {
	position: relative;
	background-image: url('../assets/backgroud.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	margin: auto;

	border: 1px solid #4A638F;
	border-radius: 4px;

	max-width: 1076px;
	min-height: 155px;
    padding: 0 10px 25px;
	top: 50px;
}

.title-selection {
	font-family: "Roboto", system-ui;
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	text-align: center;
	color: #FFFFFF;
	margin: 20px 0 20px 0px;
}


.card-title {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #FFFFFFE0;
	margin: 24px 0px 8px 0px;
}
.card-line {
	height: 1px;
	width: 100%;
	background-color: #34476a;
}

.container {
	margin: auto;
  position: relative;
  top: 10vh;
  max-width: 1077px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card-select-organization {
  width: 250px;
  height: 217px;
  background-color: #1F2B40;
  display: flex;
  flex-direction: column;
  border: 2px solid #34476A;
	border-radius: 4px;
  margin: 14px 6px;
	position: relative;

	&:hover {
		border: 2px solid #00C3DD;
		cursor: pointer;

		.tooltipAliare{
			display: flex;
			flex-direction: column;
			z-index: 9;
			width: 250px;
			font-size: 14px;

			.info-title {
				font-weight: 500;
				margin: 0;
			}
			.info-organization {
				font-weight: 400;
				color: #FFFFFF8F;
			}
		}
	}
}

.card-body {
  width: 100%;
  height: 70%;
	padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

	.card-picture {
		height: 100%;
		display: flex;
		align-items: center;
		overflow: hidden;

		.picture {
			height: 100%;
		}

		.initials {
			color: #fff;
			background: #00C3DD;
			width: 115px;
			height: 115px;
			font-size: 45px;
			border-radius: 50%;
			align-self: center;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 2px solid #34476A;
		}
	}
}

.card-divider {
  width: 100%;
  height: 1px;
  background-color: #34476A;
}

.card-footer {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 12px;
  font-size: 14px;
  color: #fff;
	justify-content: center;
}

.card-info {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 28ch; /* Define a largura máxima em 20 caracteres */
}

.card-text {
	font-size: 13px;
	color:#FFFFFFA3;
}

.searchBar {
	position: relative;
  width: 1012px;
  height: 40px;
	display: flex;
  margin: 0 25px;

	.searchBar--input:focus ~ .searchBar--input__icon {
		background-image: url('../assets/icon/lupaBlue.svg');

		&:before {
			width: 2px;
			background: #00C3DD;
		}
	}
	&--input {
		width: 100%;
		padding: 9px 40px 9px 9px;
		background: #223049;
		border: 1px solid #4A638F;
		border-radius: 4px;
		outline: none;
		color: #ffffff;

		&:focus {
			border: 2px solid #00C3DD;
		}

		&::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.56);
			opacity: 1; /* Firefox */
		}

		&__icon {
			background-image: url('../assets/icon/lupa.svg');
			background-size: 100%;
			position: absolute;
			top: 50%;
			right: 9px;
			transform: translateY(-50%);
			width: 18px;
			height: 18px;

			&:before {
				content: '';
				width: 1px;
				height: 16px;
				display: block;
				background: #4A638F;
				position: absolute;
				left: -9px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

.scroll-positioning {
	padding: 78px 0 0 0;
}
</style>
