<template>
	<div class="card__display">
		<div class="card__back" v-for="form in forms" :key="form.id">
			<el-tooltip placement="bottom" content="Acessar Detalhamento">
				<router-link class="card__link"
					:to="path(form.type)"
				>
					<div
						class="card"
						v-bind:class="{
							card__flowSuccess: form.type === 'successFlow',
							card__flow: form.type === 'flow',
							card__flowAlert: form.type === 'alertFlow',
							card__flowError: form.type === 'errorFlow',
							card__Others: form.type === 'othersFlow',
						}"
					>
							<div class="card__box">
								<div class="card__IconDiv"></div>
								<div class="card__verticalLine"></div>
								<div class="card__data">
									<div class="card__description">{{ form.description }}</div>
									<div class="card__value" v-if="form.type == 'flow'">
										{{ infoData.totalFluxos }}
									</div>
									<div class="card__value" v-if="form.type == 'successFlow'">
										{{ infoData.totalSucessos }}
									</div>
									<div class="card__value" v-if="form.type == 'alertFlow'">
										{{ infoData.totalAlertas }}
									</div>
									<div class="card__value" v-if="form.type == 'errorFlow'">
										{{ infoData.totalFalhas }}
									</div>
									<div class="card__value" v-if="form.type == 'othersFlow'">
										{{ infoData.totalOutros }}
									</div>
								</div>
							</div>
						<div class="card__arrow"></div>
					</div>
				</router-link>
			</el-tooltip>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CardInfo',
	props: {
		infoData: '',
	},
	methods: {
		path(type) {
			let payload = {
				name: 'executionLog',
				query: {
					ultimaExecucao: true,
					excetoExecucoesAnexadas: false,
					fluxosAtivos: true,
					resultado: '',
				},
			}
			if (type === 'successFlow') {
				payload.query.resultado = 'Sucesso';
			} else if (type === 'alertFlow') {
				payload.query.resultado = 'Alerta';
			} else if (type === 'errorFlow') {
				payload.query.resultado = 'Falha';
			} else if (type === 'othersFlow') {
				payload.query.resultado = ['Nao_Armazenado', 'Interrompido'];
			} 
			if (!!payload.query.resultado) {
				return payload;
			} else {
				return '';
			}
		},
	},
	data() {
		return {
			forms: [
				{
					id: '1',
					description: 'Total de Fluxos Criados',
					type: 'flow',
				},
				{
					id: '2',
					description: 'Fluxos Com Sucesso',
					type: 'successFlow',
				},
				{
					id: '3',
					description: 'Fluxos Com Alerta',
					type: 'alertFlow',
				},
				{
					id: '4',
					description: 'Fluxos Com Falha',
					type: 'errorFlow',
				},
				{
					id: '5',
					description: 'Outros',
					type: 'othersFlow',
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.card__display {
	display: flex;
	flex-wrap: wrap;

	.card__link {
		text-decoration: none;
		color: initial;
	}

	.card__back {
		background: #1f2b40;
		width: 254px;
		height: 74px;
		cursor: default;
		border-radius: 4px;
		margin: 8px 8px;

		.card {
			width: 254px;
			height: 74px;

			border-radius: 4px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 17px;

			.card__box {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;

				.card__verticalLine {
					width: 1px;
					height: 24px;
					background: #4a638f;
					margin: 0 16px;
				}

				.card__data {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.card__description {
						font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
							Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
						font-style: normal;
						font-weight: 500;
						font-size: 13px;
						line-height: 15px;
						color: rgba(255, 255, 255, 0.88);
					}

					.card__value {
						font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
							Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
						font-style: normal;
						font-weight: 400;
						font-size: 24px;
						line-height: 28px;
					}
				}
			}
		}
	}
}

.card__flow {
	background: radial-gradient(circle at top left, rgba(0, 192, 255, 0.4) 0%, transparent 20%),
		radial-gradient(circle at bottom right, rgba(0, 192, 255, 0.4) 0%, transparent 15%);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
	border: 1px solid #34476a;

	.card__IconDiv {
		background-image: url('./../assets/flow.svg');
		width: 40px;
		height: 40px;
	}

	.card__value {
		color: #00c0ff;
	}
}

.card__flowSuccess {
	background: radial-gradient(circle at top left, rgba(90, 214, 76, 0.4) 0%, transparent 20%),
		radial-gradient(circle at bottom right, rgba(89, 214, 76, 0.4) 0%, transparent 15%);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
	border: 1px solid #34476a;

	&:hover {
		box-shadow: 2px 2px 4px rgba(90, 214, 76, 0.4);
		border: 1px solid rgba(90, 214, 76, 0.4);

	}

	.card__IconDiv {
		background-image: url('./../assets/flowSuccess.svg');
		width: 40px;
		height: 40px;
	}

	.card__value {
		color: #59d64c;
	}
}

.card__flowAlert:hover {
	box-shadow: 2px 2px 4px rgba(255, 165, 59, 0.4);
	border: 1px solid rgba(255, 165, 59, 0.4);
}

.card__flowAlert {
	background: radial-gradient(circle at top left, rgba(255, 165, 59, 0.4) 0%, transparent 20%),
		radial-gradient(circle at bottom right, rgba(255, 165, 59, 0.4) 0%, transparent 15%);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
	border: 1px solid #34476a;
	//cursor: pointer;

	.card__IconDiv {
		background-image: url('./../assets/flowAlert.svg');
		width: 40px;
		height: 40px;
	}

	.card__value {
		color: #ffa53b;
	}

	.card__arrow {
		//background-image: url('./../assets/cardArrow.svg');
		background-position: center;
		background-repeat: no-repeat;
		width: 12px;
		height: 20px;
	}
}

.card__flowError:hover {
	box-shadow: 2px 2px 4px rgba(255, 73, 95, 0.4);
	border: 1px solid rgba(255, 73, 95, 0.4);
}

.card__flowError {
	background: radial-gradient(circle at top left, rgba(255, 73, 95, 0.4) 0%, transparent 20%),
		radial-gradient(circle at bottom right, rgba(255, 73, 95, 0.4) 0%, transparent 15%);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
	border: 1px solid #34476a;
	//cursor: pointer;

	.card__IconDiv {
		background-image: url('./../assets/flowError.svg');
		width: 40px;
		height: 40px;
	}

	.card__value {
		color: #ff495f;
	}

	.card__arrow {
		//background-image: url('./../assets/cardArrow.svg');
		background-position: center;
		background-repeat: no-repeat;
		width: 12px;
		height: 20px;
	}
}
.card__Others {
	background: radial-gradient(circle at top left, rgba(205, 184, 186, 0.4) 0%, transparent 20%),
		radial-gradient(circle at bottom right, rgba(205, 184, 186, 0.4) 0%, transparent 15%);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
	border: 1px solid #34476a;
	//cursor: pointer;

	.card__IconDiv {
		background-image: url('./../assets/othersFlow.svg');
		width: 40px;
		height: 40px;
	}

	.card__value {
		color: #FFFFFFE0;
	}

	.card__arrow {
		//background-image: url('./../assets/cardArrow.svg');
		background-position: center;
		background-repeat: no-repeat;
		width: 12px;
		height: 20px;
	}
}
</style>
